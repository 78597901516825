export const breadcrumbSteps = ["fornitura", "tariffa-luce", "pacchetto-assicurativo"];

export const stepCookies = {
  fornitura: "switchinCommodity",
  bolletta: "switchinBolletta",
  "tariffa-luce": "switchinProdotto",
  "pacchetto-assicurativo": "switchinAssicurazione",
};

export const fornituraAttivaCheckMapping = {
  ele: "luceAttivo",
  gas: "gasAttivo",
  dual: "dualAttivo",
};

export const fornituraCategoriaMapping = {
  ele: "luce",
  gas: "gas",
  dual: "luce-gas",
};

/* CPQ*/

export const offerteMapping = {
  en30light12mesi: {
    fasce: "E12FS",
    monoraria: "E12MS",
  },
  energiapuntofisso: {
    fasce: "ENPFS",
    monoraria: "ENPMS",
  },
  energia30light: {
    fasce: "EF24S",
    monoraria: "EM24S",
  },
  energiaplus: {
    ele: {
      email: "PLUBS",
      posta: "PLUCS",
    },
    gas: {
      email: "PLUBS",
      posta: "PLUCS",
    },
    dual: {
      email: "PLDBS",
      posta: "PLUCS",
    },
  },
};

export const priceEnergiaPlus = {
  ele: {
    email: {
      priceEle: "0,1848",
      priceGas: "0,6900",
      commGasAnno: "96",
      commGasSmc: "0.00795",
      commEle: "96",
      discountEle: "",
      discountGas: "",
    },
    posta: {
      priceEle: "0,1848",
      priceGas: "0,6900",
      commGasAnno: "96",
      commGasSmc: "0,00795",
      commEle: "96",
      discountEle: "",
      discountGas: "",
    },
  },
  gas: {
    email: {
      priceEle: "0,1848",
      priceGas: "0,6900",
      commGasAnno: "96",
      commGasSmc: "0.00795",
      commEle: "96",
      discountEle: "",
      discountGas: "",
    },
    posta: {
      priceEle: "0,1848",
      priceGas: "0,6900",
      commGasAnno: "96",
      commGasSmc: "0,00795",
      commEle: "96",
      discountEle: "",
      discountGas: "",
    },
  },
  dual: {
    email: {
      priceEle: "0,1777",
      priceGas: "0,6703",
      commGasAnno: "96",
      commGasSmc: "0,00795",
      commEle: "96",
      discountEle: "3,8",
      discountGas: "2,8",
    },
    posta: {
      priceEle: "0,1848",
      priceGas: "0,6900",
      commGasAnno: "96",
      commGasSmc: "0,00795",
      commEle: "96",
      discountEle: "",
      discountGas: "",
    },
  },
};

const commoditiesConfiguration = (flussoConfig) => [
  { id: "ele", status: flussoConfig?.luceAttivo === "true" },
  { id: "gas", status: flussoConfig?.gasAttivo === "true" },
  { id: "dual", status: flussoConfig?.dualAttivo === "true" },
];

export const initData = (flussoConfig) =>
  commoditiesConfiguration(flussoConfig).filter((comm) => comm.status)?.length === 1
    ? { fornitura: commoditiesConfiguration(flussoConfig).find((comm) => comm.status).id }
    : {};

const filterSteps = (steps, flussoConfig) =>
  steps.filter(
    (step) =>
      step !== "fornitura" ||
      commoditiesConfiguration(flussoConfig).filter((comm) => comm.status)?.length > 1
  );

export const flussiInit = {
  flussoEnergia30Cent: (...[, flussoConfig]) => {
    return {
      listaStep: filterSteps(
        ["fornitura", "tariffa-luce", "accedi-spazio-clienti", "credenziali-spazio-clienti"],
        flussoConfig
      ),
      data: initData(flussoConfig),
    };
  },
  flussoEnergiaNew: (...[, flussoConfig]) => {
    return {
      listaStep: filterSteps(
        ["welcome-step", "fornitura", "accedi-spazio-clienti", "credenziali-spazio-clienti"],
        flussoConfig
      ),
      data: initData(flussoConfig),
    };
  },
  flussoAssicurazione: (...[, flussoConfig]) => {
    return {
      listaStep: filterSteps(
        [
          "fornitura",
          "pacchetto-assicurativo",
          "accedi-spazio-clienti",
          "credenziali-spazio-clienti",
        ],
        flussoConfig
      ),
      data: initData(flussoConfig),
    };
  },
  engiePerTeZeroProblemiLuce: (location) => {
    const params = new URLSearchParams(location?.search);
    const fromApp = params.get("app") === "true";
    return {
      hideBreadcrumb: true,
      listaStep: ["pacchetto-assicurativo"].concat(
        fromApp ? "credenziali-spazio-clienti" : ["spinner"]
      ),
      data: {
        fornitura: "ele",
      },
    };
  },
  engiePerTeProdottoLuce: (location) => {
    const params = new URLSearchParams(location?.search);
    const fromApp = params.get("app") === "true";
    return {
      hideBreadcrumb: true,
      listaStep: fromApp ? ["credenziali-spazio-clienti"] : ["spinner"],
      data: {
        fornitura: "ele",
      },
    };
  },
  engiePerTeProdottoGas: (location) => {
    const params = new URLSearchParams(location?.search);
    const fromApp = params.get("app") === "true";
    return {
      hideBreadcrumb: true,
      listaStep: fromApp ? ["credenziali-spazio-clienti"] : ["spinner"],
      data: {
        fornitura: "gas",
      },
    };
  },
  engiePerTeZeroProblemiGas: (location) => {
    const params = new URLSearchParams(location?.search);
    const fromApp = params.get("app") === "true";
    return {
      hideBreadcrumb: true,
      listaStep: ["pacchetto-assicurativo"].concat(
        fromApp ? "credenziali-spazio-clienti" : ["spinner"]
      ),
      data: {
        fornitura: "gas",
      },
    };
  },
  flussoSoloLuceGiaCliente: () => {
    return {
      listaStep: ["tariffa-luce", "accedi-spazio-clienti", "credenziali-spazio-clienti"],
      data: {
        fornitura: "ele",
      },
    };
  },
  flussoSoloGasGiaCliente: () => {
    return {
      listaStep: ["accedi-spazio-clienti", "credenziali-spazio-clienti"],
      data: {
        fornitura: "gas",
      },
    };
  },
  flussoEnergia: (...[, flussoConfig]) => {
    return {
      listaStep: filterSteps(
        ["fornitura", "accedi-spazio-clienti", "credenziali-spazio-clienti"],
        flussoConfig
      ),
      data: initData(flussoConfig),
    };
  },
  flussoPlacetCasa: (_location, flussoConfig) => {
    return {
      listaStep: ["welcome-step", "accedi-spazio-clienti", "credenziali-spazio-clienti"],
      data: {
        fornitura:
          flussoConfig.luceAttivo === "true"
            ? "ele"
            : flussoConfig.gasAttivo === "true"
            ? "gas"
            : "",
      },
    };
  },
  lpJourneyProdottoLuce: () => {
    return {
      hideBreadcrumb: true,
      listaStep: ["credenziali-spazio-clienti"],
      data: {
        fornitura: "ele",
      },
    };
  },
  epzerDualSoloSceltaCliente: () => {
    return {
      listaStep: ["accedi-spazio-clienti", "credenziali-spazio-clienti"],
      data: {
        fornitura: "dual",
      },
    };
  },
  flussoEnergiaPlus: (...[, flussoConfig]) => {
    return {
      listaStep: filterSteps(
        [
          "fornitura-plus",
          "accedi-spazio-clienti-plus",
          "upselling-plus",
          "credenziali-spazio-clienti-plus",
        ],
        flussoConfig
      ),
      data: initData(flussoConfig),
    };
  },
  flussoAbbassaLaBolletta2024: (...[, flussoConfig]) => {
    return {
      listaStep: filterSteps(
        [
          "accedi-spazio-clienti-bolletta",
          "fornitura-bolletta",
          "tipologia-offerta-bolletta",
          "form-bolletta",
        ],
        flussoConfig
      ),
      data: initData(flussoConfig),
    };
  },
};

export const elencoProdottiBreadcrumb = new Map([
  ["treinuno", "3 in Uno"],
  ["zeroproblemi", "Zero Problemi"],
  ["en30light12mesi", "Energia 3.0 Light 12 Mesi"],
  ["energia30light", "Energia 3.0 Light 24 Mesi"],
  ["seguitutela", "Segui la Tutela"],
  ["greenhome", "GREEN HOME x TheFork"],
  ["smartsaveplug", "Smart&Save<sup>+</sup>"],
  ["PlacetFissaLuceDomestici", "Placet Fissa - Luce Domestici"],
  ["placetcasafissaluce", "Placet Fissa - Luce Domestici"],
  ["PlacetVariabileLuceDomestici", "Placet Variabile - Luce Domestici"],
  ["placetcasavariabileluce", "Placet Variabile - Luce Domestici"],
  ["PlacetFissaGasDomestici", "Placet Fissa - Gas Domestici"],
  ["placetcasafissagas", "Placet Fissa - Gas Domestici"],
  ["PlacetVariabileGasDomestici", "Placet Variabile - Gas Domestici"],
  ["placetcasavariabilegas", "Placet Variabile - Gas Domestici"],
  ["fixlucegasassicurazione", "Fix Luce&Gas + Assicurazione Casa"],
  ["fixduelucegasassicurazione", "Fix Luce&Gas + Assicurazione Casa"],
  ["lucegasassicurazionecasa", "Luce&Gas + Assicurazione Casa"],
  ["efix", "eFix Plus"],
  ["eflex", "eFLEX"],
  ["bestcall", "Best Call"],
  ["mgm", "Porta un Amico"],
  ["evviva", "Evviva"],
  ["only4", "Only 4 You"],
  ["mazer", "Energia & Manutenzione"],
  ["en30light12mesi-onlygasdual", "Energia 3.0 Light 12 Mesi"],
  ["energia30light-onlygasdual", "Energia 3.0 Light 24 Mesi"],
  ["engieanniversary", "ENGIE Anniversary"],
  ["energiapesozero", "Energia PESO ZERO"],
  ["zerosbalzi", "Zero Sbalzi"],
  ["engiesolarplus", "Engie Solar Plus"],
  ["eng30lightAddon-onlygasdual", "Energia 3.0 Light 24 Mesi"],
  ["eng30lightAddon", "Energia 3.0 Light 24 Mesi"],
  ["engiefit", "ACTIVE Luce e Gas"],
  ["engiemonza", "My Store"],
  ["energiapesozeroall", "Energia PESO ZERO"],
  ["estatepesozero", "Estate PESO ZERO"],
  ["index", "Surfing Luce e Gas"],
  ["energia30lightdipendenti", "ENGIE Green People"],
  ["agile", "Energia Agile"],
  ["energiaplus", "Energia Plus"],
  ["energiapuntofisso", "Energia PuntoFisso"],
  ["energiavedochiaro", "Energia VedoChiaro"],
  ["abbassalabolletta2024", "Offerta Gas Indicizzata ENGIE"],
]);

export const isFlusso = (link) => {
  const functionName = link?.split("(")?.[0];
  // eslint-disable-next-line no-script-url
  return ["javascript:genericAttivaOnline", "javascript:attivaOnlinePlacetCasa"].includes(
    functionName
  );
};

/* CPQ */

export const getProductName = (link) => {
  const functionName = link?.split("(")?.[0];
  const parameterValue = link.split("(")?.[1].split(",")?.[0].split("'")?.[1];
  // eslint-disable-next-line no-script-url
  if (functionName === "javascript:attivaOnlinePlacetCasa") {
    switch (parameterValue) {
      case "PFLDF":
        return "placetcasafissaluce";
      case "PVLDF":
        return "placetcasavariabileluce";
      case "PFGDF":
        return "placetcasafissagas";
      case "PVGDV":
        return "placetcasavariabilegas";
      default:
        return null;
    }
  }
  return parameterValue;
};
