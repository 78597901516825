import React, { useMemo } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "../components/link";
import SlideHeader from "../components/fasce/slide-header";
import useInitDataLayer from "../utility/useInitDataLayer";
import { useHandleRecaptchaBadge } from "../utility/useHandleRecaptchaBadge";

const ListEntry = ({ entry }) => (
  <li className="mappa-sito__item" key={entry.page.context.id}>
    <Link to={entry.page.path} className="mappa-sito__link">
      {entry.page.context.title}
    </Link>
    {entry.children?.length ? (
      <ul className="mappa-sito__list">
        {entry.children.map((subEntry) => (
          <ListEntry entry={subEntry} key={subEntry.page.context.id} />
        ))}
      </ul>
    ) : null}
  </li>
);

const MappSitoPage = ({ data, location }) => {
  useInitDataLayer(location);
  useHandleRecaptchaBadge(false);
  const slideData = useMemo(() => {
    return {
      titolo: {
        value: "<p>Mappa del sito</p>",
      },
      model: {
        value: ["SlideHeader"],
      },
      slide: { node: data.image },
      slideXl: { node: data.image },
    };
  }, [data]);
  const pageEntries = useMemo(() => {
    const list = data.allSitePage?.nodes;
    const fillEntry = (list, page, level = 1) => {
      const startPath = `/${page.path
        .split("/")
        .slice(1, level + 1)
        .join("/")}/`;
      const entry = list.find((item) => item.page.path === startPath);
      if (entry) {
        fillEntry(entry.children, page, level + 1);
      } else {
        list.push({
          page,
          children: [],
        });
      }
      return list;
    };
    return list.reduce((res, page) => fillEntry(res, page), []);
  }, [data]);
  return (
    <Layout location={location}>
      <Seo
        title="Mappa del sito"
        description="Mappa del sito casa.engie.it: qui trovi l'elenco di tutte le pagine disponibili."
        path="/mappa-sito/"
      />
      <div>
        <SlideHeader data={slideData} />
        <section className="section mappa-sito">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <ul className="mappa-sito__list">
                  {pageEntries?.map((entry) => (
                    <ListEntry entry={entry} key={entry.page.context.id} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};
export default MappSitoPage;
export const query = graphql`
  query {
    allSitePage(
      filter: {
        context: { sitemapXml: { eq: true }, sitemapPage: { eq: true } }
        path: { regex: "/^.+(?<!-b/)$/" }
      }
      sort: { fields: [path] }
    ) {
      nodes {
        path
        context {
          id
          title
        }
      }
    }
    image: liferayDocument(title: { eq: "4819828" }) {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`;
